<template>
    <div style="display: inline-block">
        <div class="badge-input">
            <el-input
                v-model="value"
                @input="onInput"
                :placeholder="placeholder"
            ></el-input>
            <el-icon :size="8" class="badge" @click="onClickBadge"
                ><Icon icon="psi-close"
            /></el-icon>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps({
    value: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
});
const emits = defineEmits(["update:value", "clickbadge"]);
function onInput(e) {
    props.value = e;
    emits("update:value", e);
}
function onClickBadge(e) {
    emits("clickbadge", e);
}
</script>
<style scoped>
.badge-input {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
}
.badge {
    color: white;
    width: 18px;
    height: 18px;
    background-color: var(--el-color-info-light-5);
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    left: -10px;
    top: -9px;
}
.badge:hover {
    color: var(--el-color-primary);
}
</style>
