<template>
    <div class="page-title">
        <div>
            <div
                v-if="back"
                class="el-page-header__back"
                @click="this.$router.back()"
            >
                <div class="el-page-header__title">返回</div>
                <div class="el-divider el-divider--vertical"></div>
            </div>
            {{ title }}
        </div>
        <div><slot></slot></div>
    </div>
</template>
<script>
const Title = {
    name: "Title",
    install: function (vue) {
        vue.component("Title", this);
    },
    data: function () {
        return {};
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        back: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
    watch: {},
    methods: {},
    created: function () {},
};
export default Title;
</script>

<style scope>
/*******page-title*********/
.page-title {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: stretch;
    margin-bottom: 20px;
    /* border:1px solid #e6e6e6; */
    /* border-left: 10px solid #2d8cf0; */
    /* background: linear-gradient(to right, #2d8cf0 0%, #ffffff 100%); */
}
.page-title > div {
    padding: 10px 0px 10px 10px;
    color: var(--el-text-color-primary);
    font-size: 18px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.page-title > div:last-child {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    padding-right: 20px;
}
</style>
