<template>
    <svg :class="classList">
        <use :xlink:href="iconName" :fill="color" />
    </svg>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({
    class: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        default: "currentColor",
    },
    size: {
        type: String,
        default: "20px",
    },
});

const methods = {
    install: function (vue) {
        vue.component("Icon", this);
    },
};

const classList = computed(() => {
    // return ["el-icon", props.className || ""];
    return [props.class || ""];
});

const iconName = computed(() => {
    return `#${props.icon}`;
});
</script>

<style scoped>
.icon {
    /* v-bind是Vue3才支持的功能，可以将CSS的值与js的值绑定 */
    width: v-bind("props.size");
    height: v-bind("props.size");
    position: relative;
    vertical-align: -2px;
    fill: currentColor;
}
</style>
