
import cookies from "vue-cookies";

const set = function(key,value,expires) {//expires -1：永不过期 0:会话级别 秒数：过期的秒数
    return cookies.set(key,value,expires);
}

const get = function(key) {///如果key不存在返回null
    return cookies.get(key);
}

const remove = function(key) {
    return cookies.remove(key);
}

const exist = function(key) {
    return cookies.isKey(key);
}

// const getKeys = function() {
//     return cookies.keys();
// }

const keys = {
    token: "token",//存储token
    companyId: "companyid",//存储默认的CompanyId
    user: "user",//存储默认的CompanyId
};

const prop = function (keyName,value,expires) {
    if (value !== undefined) {
        if (value === null) {
            remove(keyName);            
        } else {
            set(keyName,value,expires);
        }
    }
    return get(keyName);
}

const token = function (value) {
    return prop(keys.token, value, 0);
}

const companyId = function (value) {
    return prop(keys.companyId, value, 0);
}

const user = function (value) {
    return prop(keys.user, value, 0);
}

export default{
    token,
    companyId,
    user
}