import "@/assets/iconfont/iconfont.js";
import Icon from "@/components/Icon.vue";
import Title from "@/components/Title.vue";
import Null from "@/components/Null.vue";
import BadgeInput from "@/components/BadgeInput.vue";
const Components = {
    install:function(vue){
        vue.component('Icon', Icon);
        vue.component('Title', Title);
        vue.component('Null', Null);
        vue.component('BadgeInput', BadgeInput);
    }
}

export default Components;