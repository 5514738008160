// import { Message, MessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'

const duration = 3000;//显示时间

const message = {
    success: function (text = "成功") {
        ElMessage({
            message: text,
            type: "success",
            duration: duration,
        });
    },
    info: function (text = "消息") {
        ElMessage({
            message: text,
            type: "info",
            duration: duration,
        });
    },
    warning: function (text = "警告") {
        ElMessage({
            message: text,
            type: "warning",
            duration: duration,
        });
    },
    error: function (text = "错误") {
        ElMessage({
            message: text,
            type: "error",
            duration: duration,
        });
    },
}

export default message;